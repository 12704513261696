






























































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { Ax } from '@/utils';
import I18n from '../I18n';
import {Comp, Dict, Org, Report} from '../types';
import Report2Sheet from './Report2Sheet.vue';
import Report2SubprogramDistSheet from './subprogram-dist/Report2SubprogramDistSheet.vue';
import Report2SubprogramNoDistSheet from './subprogram-dist/Report2SubprogramNoDistSheet.vue';
import {store} from "@/modules/budget/staffing-table/common";


const i18n = new I18n('modules.budget.staffing_table.old_v1_reports.*Report2*');


@Component({
    components: {
        'report-sheet': Report2Sheet,
        'report-subprog-dist-sheet': Report2SubprogramDistSheet,
        'report-subprog-no-dist-sheet': Report2SubprogramNoDistSheet,
    }
})
export default class Report2 extends Vue {
    // region Lifecycle
    // noinspection JSUnusedLocalSymbols
    private created() {
        this.$watch('reportId', () => {
            if (this.reportId !== null) {
                this.loadReport();
            }
        });
        this.$watch('sheets', () => {
            if ((this.sheets === null) || (this.sheets.length === 0)) {
                this.selectedSheet = null;
            } else if ((this.selectedSheet === null) || (this.sheets.indexOf(this.selectedSheet) < 0)) {
                this.selectedSheet = this.sheets[0];
            }
        });
    }

    // noinspection JSUnusedLocalSymbols
    private mounted() {
        if (this.reportId !== null) {
            this.loadReport();
        }
    }
    // endregion


    // region Параметры страницы
    private get reportId(): number | null {
        const params = this.$route.params;

        const idString = params.id;
        const id = parseInt(idString);
        if (String(id) !== idString) {
            return null;
        }
        return id;
    }
    // endregion


    // region Утилиты
    private i18n = i18n;

    private toast(type: 'danger' | 'warning' | 'success', title: string, message: string) {
        this.$bvToast.toast(message, {
            title: title,
            variant: type,
            toaster: 'b-toaster-top-center',
            autoHideDelay: 5000,
            appendToast: true
        });
    }

    private get dateFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric'
        });
    }

    private get dateTimeFormat(): Intl.DateTimeFormat {
        return new Intl.DateTimeFormat(this.$i18n.locale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        });
    }
    // endregion


    // region Отчет
    private loadingReport = false;

    private report: Report | null = null;

    private selectedOrg: Org | null = store.org;

    private loadReport() {
        if (this.loadingReport) {
            console.error('Cannot load report - another loading is running');
            return;
        }

        const id = this.reportId;
        if (id === null) {
            console.error('Cannot load report - report ID is null');
            return;
        }

        this.loadingReport = true;
        this.report = null;
        Ax<Report>(
            { url: `/api/budget/staffing_table/db/report/${id}` },
            report => { this.report = report; },
            error => this.toast('danger', this.i18n.translate('error.cannot_load_report'), error.toString()),
            () => { this.loadingReport = false; }
        );
    }


    private get funcGroup(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.funcGroupObject;
    }

    private get funcGroupText(): string | null {
        const funcGroup = this.funcGroup;
        if (funcGroup === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = funcGroup.nameKk;
        } else {
            title = funcGroup.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        let code = String(funcGroup.gr);
        if (code == null) {
            return title;
        }

        while (code.length < 2) code = '0' + code;
        if (title == null) {
            return code;
        }
        return `${code} - ${title}`;
    }

    private get funcSubgroup(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.funcSubgroupObject;
    }

    private get funcSubgroupText(): string | null {
        const funcSubgroup = this.funcSubgroup;
        if (funcSubgroup === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = funcSubgroup.nameKk;
        } else {
            title = funcSubgroup.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = funcSubgroup.pgr;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get selectedOrgCode(): string | null {
        const code = this.selectedOrg?.code ?? null;
        if (code === null) {
            return null;
        }
        return code;
    }

    private get abp(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.abpObject;
    }

    private get abpText(): string | null {
        const abp = this.abp;
        if (abp === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = abp.nameKk;
        } else {
            title = abp.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = abp.abp;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get budgetProgram(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.budgetProgramObject;
    }

    private get budgetSubProgram(): Dict.EbkFunc | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.budgetSubprogramObjet;
    }

    private get getVariantText(): string | null {
        const { budgetVariantObject } = this.report || {}

        if (!budgetVariantObject) {
            return null;
        }
        const { nameRu, variantUuid } = budgetVariantObject;
        return `${nameRu}; Идентификатор: ${variantUuid};`;
    }

    private get getStaffingVersionText(): string | null {
        const { stVersion } = this.report || {};
        if (!stVersion) {
            return null;
        }
        const { id, year, title } = stVersion;

        if (!title) {
            return `#${id}, Год ${year}, Н/В ;`;
        }

        return `#${id}, Год ${year}, Н/В-${title}` }

    private get budgetProgramText(): string | null {
        const budgetProgram = this.budgetProgram;
        if (budgetProgram === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = budgetProgram.nameKk;
        } else {
            title = budgetProgram.nameRu;
        }
        if (title !== null) {
            title = title.trim();
        }

        let codeStr = null;
        const code = budgetProgram.prg

        if (code === null) {
            return title;
        }
        if (String(code).length === 1) {
            codeStr = `00${code}`
        } else if (String(code).length === 2) {
            codeStr = `0${code}`
        }
        else if (String(code).length > 2) {
            codeStr = `${code}`
        }
        if (title === null) {
            return codeStr;
        }
        return `${codeStr} - ${title}`;
    }

    private get budgetSubProgramText(): string | null {
        const budgetSubProgram = this.budgetSubProgram;
        if (budgetSubProgram === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = budgetSubProgram.nameKk;
        } else {
            title = budgetSubProgram.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        let codeStr = null;
        const code = budgetSubProgram.ppr

        if (code === null) {
            return title;
        }
        if (String(code).length === 1) {
                codeStr = `00${code}`
            } else if (String(code).length === 2) {
                codeStr = `0${code}`
            }
        if (title === null) {
            return codeStr;
        }
        return `${codeStr} - ${title}`;
    }

    private get specificity(): Dict.EbkEk | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.specificityObject;
    }

    private get specificityText(): string | null {
        const specificity = this.specificity;
        if (specificity === null) {
            return null;
        }

        let title: string | null;
        if (this.i18n.isKazakh) {
            title = specificity.nameKk;
        } else {
            title = specificity.nameRu;
        }
        if (title !== null) {
            title = title.trim();
            if (title.length === 0) {
                title = null;
            }
        }

        const code = specificity.spf;
        if (code === null) {
            return title;
        }
        if (title === null) {
            return String(code);
        }
        return `${code} - ${title}`;
    }

    private get divByPrograms(): boolean | null {
        const report = this.report;
        if (report === null) { return null; }
        return report.divByPrograms;
    }

    private get subprogDist(): boolean | null {
        const report = this.report;
        if (report === null) return null;

        return report.subprogDist;
    }

    private get reports(): Report[] | null {
        const report = this.report;
        if (report === null) return null;

        return report.reports;
    }

    private get allowedToSaveInTotal(): boolean {
        const report = this.report;
        return ((report !== null) && (report.allowedToSaveInTotal || false));
    }


    private exportToExcel() {
        const reportId = this.reportId;
        if (reportId === null) {
            console.error('Cannot export report - report ID is null');
            return;
        }

        const url = `${location.protocol}//${location.host}/api/budget/staffing_table/db/report/${reportId}/export-to-excel.xlsx`;
        window.open(url, '_blank', 'noopener noreferrer', false);
    }

    private createChildrenForSubprogramDist() {
        const report = this.report;
        if (report === null) {
            console.error('Cannot create child reports - report is null');
            return;
        }

        const reportId = report.id;
        if (reportId === null) {
            console.error('Cannot create child reports - report ID is null');
            return;
        }

        if (this.loadingReport) {
            console.error('Cannot create child reports - another loading is running');
            return;
        }

        this.loadingReport = true;

        Ax(
            { url: `/api/budget/staffing_table/db/report-2/${reportId}/subprogram-dist/create-children`, method: 'POST' },
            () => {
                setTimeout(() => { this.loadReport(); });
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_create_child_reports_for_subprogram_dist'), error.toString()),
            () => { this.loadingReport = false; }
        );
    }

    private parseDate(date: string): number {
        const parts = date.split('.');
        if (parts.length === 3) {
            const year = parseInt(parts[0]);
            const month = parseInt(parts[1]) - 1;
            const day = parseInt(parts[2]);
            return new Date(year, month, day).getTime();
        } else {
            throw new Error('Cannot parse string to Date');
        }
    }

    private sendToTotal() {
        if (this.loadingReport) {
            console.error('Cannot send report to total - another loading is running');
            return;
        }

        const report = this.report;
        if (report === null) {
            console.error('Cannot send report to total - report is null');
            return;
        }

        const reportId = report.id;
        if (reportId === null) {
            console.error('Cannot send report to total - report ID is null');
            return;
        }

        this.loadingReport = true;
        Ax(
            { url: `/api/budget/staffing_table/db/report-2/${reportId}/send-to-total`, method: 'POST' },
            () => this.toast('success', '', this.i18n.commonTranslate('saved')),
            error => { console.error(error.toString());
                console.log(error)
                this.toast('danger', this.i18n.translate('error.cannot_send_report_to_total'), error.toString())},
            () => { this.loadingReport = false; }
        );
    }
    // endregion


    // region Листы
    private get sheets(): Report.Version2.Sheet[] | null {
        const report = this.report;
        if (report === null) {
            return null;
        }

        return report.sheets;
    }

    private get sheetOptions(): Array<Comp.DropdownItemDef<Report.Version2.Sheet>> | null {
        const sheets = this.sheets;
        if (sheets === null) {
            return null;
        }

        const isKazakh = this.i18n.isKazakh;

        const create = (sheet: Report.Version2.Sheet): Comp.DropdownItemDef<Report.Version2.Sheet> => {
            let text: string;
            if (isKazakh) {
                text = sheet.titleRu;
            } else {
                text = sheet.titleKk;
            }

            return {
                value: sheet,
                text
            };
        };

        return sheets.map(create);
    }

    private selectedSheet: Report.Version2.Sheet | null = null;

    private get selectedFirstSheet(): boolean {
        const sheets = this.sheets;
        if ((sheets === null) || (sheets.length === 0)) {
            return false;
        }

        const selectedSheet = this.selectedSheet;
        if (selectedSheet === null) {
            return false;
        }

        return (sheets.indexOf(selectedSheet) === 0);
    }

    private budgetSubprogramChanged(subprogramCode: number | null) {
        if (this.loadingReport) {
            console.error('Cannot save budget subprogram - another loading is running');
            return;
        }

        const sheet = this.selectedSheet;
        if (sheet === null) {
            console.error('Cannot save budget subprogram - no sheet selected');
            return;
        }

        const sheetId = sheet.id;

        let url: string;
        if (subprogramCode === null) {
            url = `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/budget-subprogram`;
        } else {
            url = `/api/budget/staffing_table/db/report-2/sheet/${sheetId}/budget-subprogram?code=${subprogramCode}`;
        }

        this.loadingReport = true;
        Ax(
            { url, method: 'POST' },
            () => {
                sheet.budgetSubprogram = subprogramCode;
                this.selectedSheet = null;
                setTimeout(() => {
                    this.selectedSheet = sheet;
                });
            },
            error => this.toast('danger', this.i18n.translate('error.cannot_save_budget_subprogram'), error.toString()),
            () => { this.loadingReport = false; }
        );
    }
    // endregion
}
